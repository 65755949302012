<template>
  <div class="container-dash">
    <div class="top">
      <h1 class="titulo">Bienvenido {{ usuario.nombres }}</h1>
      <v-btn
        text
        large
        rounded
        class="btn-descargar"
        @click="crearPdf"
        :loading="descargarPdf"
      >
        <v-icon left> mdi-download </v-icon> Descargar
      </v-btn>
    </div>

    <!-- Reemplazamos v-tabs por v-btn-toggle -->
<v-btn-toggle
  v-model="selectedCountry"
  mandatory
  class="my-4 country-toggle"
  active-class="btn-active"
>
  <v-btn
    value="argentina"
    class="country-btn"
  >
    Argentina
  </v-btn>
  <v-btn
    value="mexico"
    class="country-btn"
  >
    México
  </v-btn>
  <v-btn
    value="brasil"
    class="country-btn"
  >
    Brasil
  </v-btn>
</v-btn-toggle>


    <div class="content">
      <Filtros :selectedCountry="selectedCountry" />
      <div v-bind:class="[descargarPdf ? 'formato-pdf' : 'graficas']">
        <img
          src="../assets/biomakers_logo.png"
          class="logo-biomakers"
          v-if="descargarPdf"
        />
        <div class="cards-total">
          <CardTotal
            :titulo="'Total muestras'"
            :icono="'muestra'"
            :url="'/total-muestras'"
            :selectedCountry="selectedCountry"
          />
          <CardTotal
            :titulo="'Clientes sponsor'"
            :icono="'cliente'"
            ref="cardSponsors"
            :url="'/total-sponsors'"
            :selectedCountry="selectedCountry"
          />
          <CardTotal
            :titulo="'Tiempo informe'"
            :textoOpcional="'*promedio'"
            :icono="'tiempo'"
            :url="'/tiempo-informe'"
            :selectedCountry="selectedCountry"
          />
        </div>
        <v-card elevation="1">
          <span class="nombre-grafica">Evolución de muestras</span>
          <hr />
          <div class="grafica">
            <EvolucionDeterminaciones :selectedCountry="selectedCountry" />
          </div>
        </v-card>
        
        <v-card elevation="1">
          <span class="nombre-grafica">Evolución de ingreso test</span>
          <hr />
          <div class="grafica">
            <EvolucionIngresoMuestras :selectedCountry="selectedCountry" />
          </div>
        </v-card>

        <v-card elevation="1">
          <span class="nombre-grafica">Evolución de tiempo de informe</span>
          <hr />
          <div class="grafica">
            <EvolucionTiempoInforme :selectedCountry="selectedCountry" />
          </div>
        </v-card>

        <div class="html2pdf__page-break"></div>

        <div class="dos-columnas">
          <v-card elevation="1">
            <span class="nombre-grafica">Determinaciones por tipo tumoral</span>
            <hr />
            <div class="grafica-columna">
              <DeterminacionesTipoTumoral :selectedCountry="selectedCountry" />
            </div>
          </v-card>

          <v-card elevation="1">
            <span class="nombre-grafica"
              >Determinaciones resueltas por país de origen</span
            >
            <hr />
            <div class="grafica-columna">
              <DeterminacionesPais :selectedCountry="selectedCountry" />
            </div>
          </v-card>
        </div>

        <v-card elevation="1">
          <span class="nombre-grafica">Tipo de resultado</span>
          <hr />
          <div class="grafica">
            <TipoResultado  :selectedCountry="selectedCountry" />
          </div>
        </v-card>

        <div class="html2pdf__page-break"></div>

        <v-card elevation="1">
          <span class="nombre-grafica">Mutaciones por tipo tumoral</span>
          <hr />
          <div class="grafica">
            <MutacionesTipoTumoral  :selectedCountry="selectedCountry" />
          </div>
        </v-card>

        <v-card elevation="1">
          <span class="nombre-grafica">Mutaciones en biomarcadores</span>
          <v-progress-circular
            v-if="cargandoDatosVariantes"
            indeterminate
            color="purple"
          ></v-progress-circular>
          <hr />
          <div class="grafica">
            <MutacionesBiomarcadores @cargandoDatos="mostrarLoader"   :selectedCountry="selectedCountry" />
          </div>
        </v-card>

        <v-card elevation="1">
          <span class="nombre-grafica">Resultados TPS y CPS para PDL1</span>
          <v-progress-circular
            v-if="cargandoDatosVariantes"
            indeterminate
            color="purple"
          ></v-progress-circular>
          <hr />
          <div class="grafica">
            <ResultPdl1 @cargandoDatos="mostrarLoader"  :selectedCountry="selectedCountry" />
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Filtros from "../components/Filtros";
import CardTotal from "../components/CardTotal";
import EvolucionDeterminaciones from "../components/Graficas/EvolucionDeterminaciones";
import EvolucionTiempoInforme from "../components/Graficas/EvolucionTiempoInforme";
import DeterminacionesPais from "../components/Graficas/DeterminacionesPais";
import EvolucionIngresoMuestras from "../components/Graficas/EvolucionIngresoMuestras";
import TipoResultado from "../components/Graficas/TipoResultado";
import MutacionesTipoTumoral from "../components/Graficas/MutacionesTipoTumoral";
import DeterminacionesTipoTumoral from "../components/Graficas/DeterminacionesTipoTumoral";
import MutacionesBiomarcadores from "../components/Graficas/MutacionesBiomarcadores.vue";
import ResultPdl1 from "../components/Graficas/ResultPdl1.vue";
import { mapState } from "vuex";
import * as html2pdf from "html2pdf.js";
export default {
  name: "Dashboard",
  components: {
    Filtros,
    CardTotal,
    EvolucionDeterminaciones,
    EvolucionTiempoInforme,
    DeterminacionesPais,
    TipoResultado,
    DeterminacionesTipoTumoral,
    MutacionesTipoTumoral,
    MutacionesBiomarcadores,
    ResultPdl1,
    EvolucionIngresoMuestras,
  },
  data() {
    return {
      descargarPdf: false,
      cargandoDatosVariantes: false,
      selectedCountry: 'argentina', // default country
    };
  },
  watch: {
  selectedCountry(newCountry) {
    this.$store.commit('setSelectedCountry', newCountry);
    // Puedes reiniciar los filtros si lo deseas
    // this.$store.dispatch('limpiarFiltros');
    // Vuelve a inicializar la fecha desde
    this.$store.dispatch('inicializarFechaDesde');
  },
},
  computed: mapState(["filtros", "usuario"]),
  methods: {
    mostrarLoader(valor) {
      this.cargandoDatosVariantes = valor;
    },
    crearPdf() {
      this.descargarPdf = true;
      const $elementoParaConvertir = document.querySelector(".graficas");
      html2pdf()
        .set({
          margin: 0,
          filename: "reporte_dashboard.pdf",
          image: {
            type: "jpeg",
            quality: 0.98,
          },
          html2canvas: {
            scale: 3,
            letterRendering: true,
          },
          jsPDF: {
            unit: "in",
            format: "a3",
            orientation: "portrait",
          },
        })
        .from($elementoParaConvertir)
        .save()
        .then(() => {
          this.descargarPdf = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.cards-total {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1em;
  width: 100%;
}

.container-dash {
  margin: 4rem 0 1em;
}

.content {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 22% 76%;
  gap: 1.5rem;
}

.dos-columnas {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.grafica {
  width: 96%;
  height: fit-content;
  margin: 1.5rem;
}

.grafica-columna {
  width: 92%;
  height: fit-content;
  margin: 1rem;
}

.graficas,
.formato-pdf {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.formato-pdf {
  background-color: #fcfaff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI2MDciIHZpZXdCb3g9IjAgMCAxNDQwIDYwNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNjA3VjBIMTQ0MVYzMTguNUwxMDE5LjQxIDU0NkwwIDYwN1oiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9Ii0wLjUwMDcwNCIgeTE9IjM0MC41IiB4Mj0iMTQ0MS41IiB5Mj0iMzM3LjQ5MSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjM0YyNTYyIi8+CjxzdG9wIG9mZnNldD0iMC4zMzMzMzMiIHN0b3AtY29sb3I9IiM0QzI3ODMiLz4KPHN0b3Agb2Zmc2V0PSIwLjY2MTQ1OCIgc3RvcC1jb2xvcj0iIzY3NDc5NiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM5RTg4QkUiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 2em;
}
.logo-biomakers {
  width: 200px;
}

.nombre-grafica {
  font-weight: 600;
  font-size: 18px;
  padding: 1.5rem;
  display: inline-block;
}

.titulo {
  color: white;
  font-weight: 600;
  font-size: 36px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.btn-descargar {
  text-transform: capitalize;
  background: #842e7c;
  color: white;
}



</style>